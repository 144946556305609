// Load intro video after page load
export default function useIntroVideo () {
    document.addEventListener( "DOMContentLoaded", () => {
        const video = document.getElementById( "landing-video" )

        video.autoplay = true
        video.load()
        video.play()
    })
}
