// Display header variants in a loop
export default function useHeaderVariants () {
    const DELAY = 1200 // ms
    const VARIANT_CLASS_NAME = "introMainHeader-variant"
    const VISIBLE_VARIANT_CLASS_NAME = `${ VARIANT_CLASS_NAME }--visible`
    const elements = document.querySelectorAll( `.${ VARIANT_CLASS_NAME }` )

    function toggleCurrentElementVisibility ( currentIndex = 0 ) {
        const element = elements[currentIndex]

        if ( element ) {
            element.classList.add( VISIBLE_VARIANT_CLASS_NAME )
            setTimeout( () => {
                element.classList.remove( VISIBLE_VARIANT_CLASS_NAME )

                const nextIndex = ( currentIndex + 1 ) % elements.length

                toggleCurrentElementVisibility( nextIndex )
            }, DELAY )
        }
    }

    toggleCurrentElementVisibility() // Display the first element immediately
}
