import useCarouselManager from "marketingWebsite/utils/useCarouselManager.js"
import useIntercom from "marketingWebsite/utils/useIntercom.js"
import useSmoothScroll from "marketingWebsite/utils/useSmoothScroll.js"
import {
    useHeaderVariants,
    useIntroVideo,
    useLandingVideoPopup,
    useTestimonialCarousel
} from "marketingWebsite/homepage/index.js"

// Aggregate and calls functions that are unique only for marketing homepage site

useHeaderVariants()
useIntroVideo()
useLandingVideoPopup()
useTestimonialCarousel()
useCarouselManager({
    carouselRoot: ".butter-carousel",
    nextControl: ".butter-carousel-control--next",
    prevControl: ".butter-carousel-control--prev",
    viewport: ".butter-carousel-viewport"
})
useSmoothScroll()
useIntercom()
