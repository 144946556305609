import wait from "marketingWebsite/utils/wait.js"

// Add carousel effect to testimonial section
export default function useTestimonialCarousel () {
    const carousel = document.querySelector( "#landingCarousel" )
    const slides = carousel.querySelectorAll( ".landing-carousel-slide" )
    const indicators = carousel.querySelectorAll( ".carousel-indicators li" )

    let currentSlide = 0
    let isPaused = false

    // Add click event listeners to indicators
    indicators.forEach( ( indicator, indexu ) => {
        indicator.addEventListener( "click", handleClick )
    })

    async function handleClick ( event ) {
        slides.forEach( ( slide ) => {
            slide.classList.add( "hold-transition" )
            slide.classList.remove(
                "carousel-item-prev",
                "carousel-item-next"
            )
        })

        const index = Array.prototype.indexOf.call( indicators, event.target )
        const isReverse = index < currentSlide
        const addedClass = isReverse
            ? "carousel-item-prev"
            : "carousel-item-next"

        slides[index].classList.add( addedClass )

        await wait()

        slides.forEach( ( slide ) => {
            slide.classList.remove( "hold-transition" )
        })
        await showSlide( index, isReverse )
        await pauseCarousel()

        return
    }

    // Show slide function with fade transition
    async function showSlide ( n, isReverse ) {
        let prevSlide,
            nextSlides = []
        const countSlides = slides.length

        if ( isReverse ) {
            nextSlides.push( currentSlide )
            currentSlide = ( n + slides.length ) % countSlides
        }
        else {
            prevSlide = currentSlide
            currentSlide = ( n + slides.length ) % countSlides
        }

        nextSlides.push( ( currentSlide + 1 ) % countSlides )

        slides.forEach( ( slide ) => {
            slide.classList.add( "hold-transition" )

            if ( isReverse ) {
                slide.classList.remove( "carousel-item-next" )
            }
            else {
                slide.classList.remove( "carousel-item-prev" )
            }
        })

        await wait()

        slides.forEach( ( slide, index ) => {
            const isActive = index === currentSlide
            const isPrev = index === prevSlide
            const isNext = nextSlides.includes( index ) // Define the next slide
            slide.classList.remove( "hold-transition" )
            slide.classList.toggle( "carousel-item-next", isNext ) // 100
            slide.classList.toggle( "carousel-item-prev", isPrev ) // -100
            slide.classList.toggle( "active", isActive ) // 0
            indicators[index].classList.toggle( "active", isActive )
        })

        return
    }

    // Change slide function with fade transition
    async function changeSlide ( n ) {
        await showSlide( currentSlide + n )

        return
    }

    // Auto slide change every 6 seconds, with pause on hover
    const carouselInterval = setInterval( async () => {
        if ( !isPaused ) {
            await changeSlide( 1 )
        }

        return
    }, 6000 )

    // Pause carousel on hover
    carousel.addEventListener( "mouseenter", () => {
        isPaused = true
    })

    // Resume carousel on mouse leave
    carousel.addEventListener( "mouseleave", () => {
        isPaused = false
    })

    // Function to pause the carousel
    async function pauseCarousel () {
        isPaused = true

        await wait( 7000 )

        isPaused = false

        return
    }
}
