/* global YT */

// Show landing video in popup on click
export default function useLandingVideoPopup () {
    let dialog, player
    let videoComplete = false

    // This function creates an <iframe> (and YouTube player)
    // after the API code downloads.
    function createYoutubeInstance () {
        const ytDomainPrefix = "https://www.youtube.com/"
        const ytLibSrc = `${ ytDomainPrefix }iframe_api`
        const firstScriptTag = document.getElementsByTagName( "script" )[0]

        // intially loading of the library
        // which then loads the iframe launcher automatically
        if ( !firstScriptTag.src.includes( ytDomainPrefix ) ) {
            const tag = document.createElement( "script" )
            tag.src = ytLibSrc
            firstScriptTag.parentNode.insertBefore( tag, firstScriptTag )
        }
        else {
            // if user reopens modal, or we've already loaded the iframe library,
            // run the iframe launcher instead
            window.onYouTubeIframeAPIReady()
        }
    }

    // YT iframe API required this function in window scope
    window.onYouTubeIframeAPIReady = () => {
        player = new YT.Player(
            "youtube-popup-player",
            {
                events: {
                    onReady: onYoutubeInstanceReady,
                    onStateChange: onYoutubeInstanceStateChange
                },
                height: "620",
                playerVars: {
                    autoplay: 1,
                    controls: 0,
                    enablejsapi: 1,
                    modestbranding: true,
                    playsinline: 1,
                    rel: 0,
                },
                videoId: "p4n3EUpVcuU",
                width: "1100",
            }
        )
        videoComplete = false
    }

    // The API will call this function when the video player is ready.
    function onYoutubeInstanceReady ( event ) {
        event.target.playVideo()
    }

    function onYoutubeInstanceStateChange ( event ) {
        if ( event.data == YT.PlayerState.ENDED && !videoComplete ) {
            videoComplete = true
            setTimeout( stopYoutubeInstance, 250 )
        }
    }

    function stopYoutubeInstance () {
        player && player.stopVideo()
        dialog.close()
    }

    function handleCloseDialog ( event ) {
        if ( event.target === this ) {
            stopYoutubeInstance()
        }
    }

    function createDialog () {
        dialog = document.createElement( "dialog" )
        dialog.classList.add( "landingVideoPopup-popup" )

        const youtubePlayerEl = document.createElement( "div" )
        youtubePlayerEl.id = "youtube-popup-player"

        dialog.appendChild( youtubePlayerEl )
        document.body.appendChild( dialog )

        dialog.addEventListener( "click", handleCloseDialog )

        createYoutubeInstance()
    }

    function handleClickPlay ( e ) {
        e.preventDefault()

        if ( dialog ) {
            if ( player ) {
                videoComplete = false
                player.seekTo( 0 )
                player.playVideo()
            }
        }
        else {
            createDialog()
        }

        dialog.showModal()
    }

    document.addEventListener( "DOMContentLoaded", () => {
        const button = document.querySelector( ".popup-youtube" )

        button.addEventListener( "click", handleClickPlay )
    })
}
