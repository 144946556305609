// Add carousel feature
// Corresponding styles can be found in butter-carousel.scss
export default function useCarouselManager ({ carouselRoot, nextControl, prevControl, viewport }) {
    const carouselsList = document.querySelectorAll( carouselRoot )

    carouselsList.forEach(
        ( carouselRootEl ) => useCarousel({
            carouselRoot: carouselRootEl,
            nextControl,
            prevControl,
            viewport
        })
    )
}

function useCarousel ({ carouselRoot, nextControl, prevControl, viewport }) {
    const viewportEl = carouselRoot.querySelector( viewport )
    const prevControlEl = carouselRoot.querySelector( prevControl )
    const nextControlEl = carouselRoot.querySelector( nextControl )

    const presentSlides = Array.from( viewportEl.children ).map(
        ( e ) => ({
            offsetLeft: e.offsetLeft,
            offsetWidth: e.offsetWidth
        })
    )

    let shiftCount = 0
    const canShiftBy = setupCarouselShift( viewportEl, presentSlides )

    function setupCarouselShift ( viewportEl, slides ) {
        const {
            width: viewportElWidth
        } = viewportEl.getBoundingClientRect()

        // all slides have same offsetWidth, so we can take the first
        const visibleElements = Math.round( viewportElWidth / slides[0].offsetWidth )

        // how many times can we shift in total
        const canShift = Math.floor( slides.length - visibleElements )

        return canShift
    }

    function shift () {
        if ( shiftCount < canShiftBy ) {
            shiftCount++
        }
        else { shiftCount = 0 }

        carouselScroll( shiftCount )
    }

    function unshift () {
        if ( shiftCount > 0 ) {
            shiftCount--
        }
        else {
            shiftCount = canShiftBy
        }

        carouselScroll( shiftCount )
    }

    function getSlideOffset ( slide ) {
        return presentSlides[slide].offsetLeft
    }

    function carouselScroll ( currentSlide ) {
        viewportEl.scrollLeft = getSlideOffset( currentSlide )
    }

    function init () {
        nextControlEl.addEventListener(
            "click",
            shift
        )

        prevControlEl.addEventListener(
            "click",
            unshift
        )
    }

    init()
}
