const TIMEOUT = 10000 // 10s

// Init Intercom script
// Intercom is activated on first scroll or after defined TIMEOUT
// whichever occurs first
export default function useIntercom () {
    let loaded = false

    function defineIntercom () {
        const intercomFunc = function () {
            // eslint-disable-next-line prefer-rest-params
            intercomFunc.c( arguments )
        }
        intercomFunc.q = []

        intercomFunc.c = function ( args ) {
            intercomFunc.q.push( args )
        }
        window.Intercom = intercomFunc
    }

    function reattachActivator () {
        window.Intercom( "reattach_activator" )
        window.Intercom( "update", window.intercomSettings )
    }

    function loadScript () {
        if ( loaded ) {
            return
        }

        loaded = true

        const scriptEl = document.createElement( "script" )
        scriptEl.type = "text/javascript"
        scriptEl.async = true
        scriptEl.src = `https://widget.intercom.io/widget/${ window.intercomSettings.app_id }`

        document.body.appendChild( scriptEl )
    }

    function init () {
        if ( !window.intercomSettings || !window.intercomSettings.app_id ) {
            // define Intercom as mockup only to avoid errors on missing settings
            // ex. on dev environment
            defineIntercom()

            return
        }

        if ( typeof window.Intercom === "function" ) {
            reattachActivator()
        }
        else {
            defineIntercom()
            window.addEventListener( "scroll", loadScript, { once: true })
            setTimeout( loadScript, TIMEOUT )
        }
    }

    init()
}
